.cards {
    @apply mt-2 pb-4 relative;
}
.card {
    @apply bottom-0 rounded-lg p-0 mt-2 pb-4 to-blue-500 relative transition-shadow duration-200;
}

.cardNotification {
    @apply p-0 relative;
    min-height: 119px;
}
/* .card:first-child {
    @apply ml-0;
} */
.card-header {
    @apply px-2 text-lg text-gray-700 font-normal;
}
.card-pseudo {
    @apply font-bold;
    font-size: 21px;
    color: #46519f;
}
.card-profile-status {
    @apply text-base;
    /* color: #2e3e5c; */
    color: #000a53;
}
.card-item-question {
    @apply text-sm break-words inline-block tracking-wide pb-2;
    /* color: #2e3e5c; */
    color: #000a53;
    height: auto;
    max-width: 100%;
}

.card-item-question-non-expanded {
    @apply text-sm break-words inline-block tracking-wide cursor-pointer;
    /* color: #2e3e5c; */
    color: #000a53;
    /* height: 5rem; */
    overflow: hidden;
    max-width: 100%;
}

.card-item-question-expanded {
    @apply text-sm sm:text-base break-words inline-block tracking-wide cursor-pointer;
    /* color: #2e3e5c; */
    color: #000a53;
    height: auto;
    max-width: 100%;
}
.card-actions {
    @apply relative bottom-0 flex justify-end text-sm text-secondary p-2 pt-0;
}
/* PROFILE CARD */
.card_profile .card_profile_images .card_profile_images_cover {
    @apply relative rounded-lg h-auto;
}
.card_profile .card_profile_images .card_profile_images_avatar {
    @apply relative mx-auto mb-0;
}
.card_profile .card_profile_images .card_profile_images_avatar img {
    @apply rounded-full mx-auto;
}

/* .card_content {
    @apply pb-3;
} */
.card_actions {
    @apply flex flex-nowrap justify-between w-full mt-4 absolute bottom-0 left-0 pt-4 pb-2 pl-2 pr-3;
}

.card_type {
    @apply absolute right-2 top-0;
}
.card_question {
    @apply text-graySkills;
}
.card_job {
    @apply text-purpleSkills;
}
.card_devis {
    @apply text-greenSkills;
}
.card_news {
    @apply text-graySkills;
}

.shape {
    position: absolute;
    left: 0;
    top: auto;
    right: 0;
    bottom: -1px;
    z-index: 0;
    width: 100%;
    height: 40px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    background-color: #fff;
}

.hidden-need {
    @apply transition-opacity duration-700 ease-in opacity-0;
}