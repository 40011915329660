.mainSearch {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    @apply backdrop-filter backdrop-blur-xl;
}
.mainSearch .mainSearch-content {
    @apply rounded-xl p-0 m-0 relative h-full;
    width: 100%;
}
.mainSearch .mainSearch-title {
    @apply text-lg text-white bg-secondary font-bold  px-8 py-4 border-b border-gray-200;
}
.mainSearch .mainSearch-main {
    @apply max-w-2xl m-auto;
}
.mainSearch .mainSearch-actions {
    @apply flex justify-end px-8 py-4;
}
.mainSearch .mainSearch-close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
}
