.optionWrapper_overlay {
    @apply fixed top-0 left-0 w-0 h-0 z-10;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999999999;
}
.optionWrapper_overlay.active {
    @apply fixed top-0 left-0 w-full h-full;
    background: rgba(0, 0, 0, 0.5);
}
.optionWrapper {
    @apply top-0 h-full bg-gray-100 shadow-2xl fixed transition-all duration-300 w-full md:w-4/12;
    right: -40%;
    /* width: 40%; */
}
.optionWrapper.active {
    @apply right-0;
}

.optionWrapper_close {
    @apply absolute top-10 left-10 bg-transparent transition-all duration-300;
    cursor: pointer;
}
.optionWrapper.active .optionWrapper_close {
    @apply -left-10;
}

.optionWrapper_content {
    @apply flex flex-col items-center justify-center mt-4;
}

.optionWrapper_actions {
    @apply flex flex-row items-center justify-center absolute bottom-4 left-0 w-full h-10;
}
