.CookieConsent {
    @apply fixed block bottom-20 left-0 sm:bottom-5 sm:left-5 w-auto sm:max-w-sm h-auto bg-white p-6 rounded-lg shadow-lg text-mainText text-left text-sm z-20;
}
.CookieConsent_content h3 {
    @apply text-lg font-bold mb-4;
}
.CookieConsent_content p {
    @apply text-mainText mb-2;
}
.CookieConsent_content a {
    @apply text-mainText font-bold;
}
.CookieConsent_settings {
    @apply my-3;
}
.CookieConsent_checkbox {
    @apply flex justify-start items-center gap-2 my-2 font-semibold;
}
.CookieConsent_actions {
    @apply sm:flex sm:justify-start;
}
.CookieConsent_actions button {
    @apply w-full sm:w-auto;
}
.CookieConsent_deny {
    @apply text-mainText pt-2;
}
