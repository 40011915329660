.sideNav_link {
    @apply text-purpleSkills font-bold py-2 px-2 overflow-hidden;
}

.sideNav_link.active {
    @apply text-blueMain;
}

.sideNav_link:not(.active):hover {
    @apply text-blueMain cursor-pointer bg-white rounded-3xl;
}
