.form {
    @apply w-full flex flex-wrap pb-4;
}

.section_title {
    @apply text-center text-xl font-bold mt-4 text-purpleSkills py-2 p-1 px-2 mb-2 rounded w-full;
}

.group {
    @apply h-auto;
    min-height: 70px;
}

.groupMdp {
    @apply h-auto;
    min-height: 30px;
}

/* INPUTS */
.input {
    @apply border-b border-graySkills font-medium text-mainText h-11 mb-0;
    @apply w-full flex-initial mt-0 text-base;
}

#input_landingpage:disabled {
    background-color: white;
}

.input_floating {
    @apply block py-2.5 px-4 w-full text-sm text-purpleSkills bg-transparent border-0 border-b border-graySkills appearance-none focus:outline-none focus:ring-0 focus:border-blueMain;
}
.input_flat {
    @apply block mb-2 bg-transparent border-none text-sm bg-blancSkills rounded-xl text-mainText font-bold placeholder-purpleSkills placeholder:font-bold text-left p-4 w-full appearance-none focus:outline-none focus:ring-0;
}
.input_ai {
    @apply border-b border-white font-medium text-white h-11 mb-0;
    @apply w-full flex-initial mt-0 text-base;
}
.input_labelFloating {
    @apply font-medium peer-focus:text-sm peer-placeholder-shown:text-sm peer-focus:pl-0  absolute text-sm text-graySkills duration-300 transform -translate-y-7  top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blueMain peer-placeholder-shown:translate-y-0  peer-focus:-translate-y-7;
}
.inputAi_floating {
    @apply block py-2.5 px-4 w-full text-sm text-white bg-transparent border-0 border-b border-white appearance-none focus:outline-none focus:ring-0;
}
.inputAi_labelFloating {
    @apply font-medium peer-focus:text-sm peer-placeholder-shown:text-sm peer-focus:pl-0 absolute text-sm text-white duration-300 transform -translate-y-7  top-3 -z-10 origin-[0] peer-focus:left-0 peer-placeholder-shown:translate-y-0  peer-focus:-translate-y-7;
}

.input_left {
    @apply pl-12;
}
.input_peerleft {
    @apply pl-4;
}
.input_rounded {
    @apply rounded-full border-none;
}

.input_bubble {
    @apply border-none  rounded-r-3xl rounded-b-3xl;
}

.input_border {
    @apply border border-gray-300;
}
.input_borderB {
    @apply border-b border-gray-300;
}
.input_checkbox {
    @apply border-0 w-5 h-5 mt-4 mr-2 relative;
    top: -2px;
}
.input:focus,
.input_ai:focus,
.input:focus:not(.input_error),
/* input[type='text']:focus {
    @apply border-blueMain;
    outline: none;
} */
.input[type='text']:focus {
    /* @apply border-blueMain; */
    outline: none;
}
/* .input_ai:focus {
    @apply border-white;
    outline: none;
} */
.input_label {
    @apply text-graySkills;
    @apply font-medium;
    @apply mb-0 block;
}
.inputAi_label {
    @apply text-white;
    @apply font-medium;
    @apply mb-0 block;
}
.input_label_checkbox {
    @apply mt-3;
}
.input_error {
    @apply text-danger-400 border-danger-500;
}
textarea[autoresize] {
    display: block;
    height: 100px;
    overflow: auto;
    resize: none;
    outline: none;
}

/* OPTIONS */
.input_option {
    @apply absolute cursor-pointer text-gray-500 w-full;
}
.input_option .icon_right {
    @apply absolute right-2;
}
.input_option .icon_left {
    @apply absolute left-1;
}

.input_option .icon_left_MainSeach {
    @apply absolute left-1 top-3;
}

.big-icon {
    @apply h-10 w-10;
    position: relative;
    top: 2px;
    right: -1px;
}

.medium-icon {
    @apply h-7 w-7;
    position: relative;
    top: 2px;
    right: -1px;
}

.small-icon {
    @apply h-5 w-5;
    position: relative;
    top: 7px;
    right: -8px;
}

/* TAGS */
.react-tags__search-wrapper {
    @apply overflow-hidden;
}
.react-tags__search-input {
    @apply bg-transparent w-full;
}
.input_tags {
    @apply h-auto mb-2 py-1;
}
.input_ai_tags {
    @apply h-auto mb-2 py-1;
}
.input_tags .react-tags__selected {
    @apply flex flex-wrap gap-x-2 text-purpleSkills;
}
.input_tags .react-tags__search-input {
    @apply border-0 my-2 text-sm text-purpleSkills placeholder-graySkills;
    outline: none;
}
.input_ai_tags .react-tags__selected {
    @apply flex flex-wrap gap-x-2 text-purpleSkills;
}
.input_ai_tags .react-tags__search-input {
    @apply border-0 my-2 text-sm text-white placeholder-graySkills;
    outline: none;
}
/* UPLOADS */
.files_upload > :first-child {
    @apply relative cursor-pointer flex border-2 border-gray-300 border-dashed rounded-lg h-full w-full bg-transparent justify-center items-center text-center text-sm p-0 my-2;
}
/* .files_upload > :first-child { */
/*     @apply w-full flex justify-center items-center p-0 cursor-pointer; */
/* } */
.files_upload.files_upload-avatar {
    @apply h-44 w-44 !rounded-full p-0;
}
.files_upload.files_upload-avatar > :first-child {
    @apply !rounded-full;
}
.files_upload.files_upload-single {
    @apply h-44 w-full rounded-lg p-0;
}
.files_upload .files_init {
    @apply absolute z-0 p-0;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    cursor: pointer;
}
.files_upload .files-dropzone-active p {
    @apply text-secondary;
}
.advanced-cropper-preview__content {
    width: 100% !important;
    height: 100% !important;
}
.files_upload-preview img {
    @apply object-contain !h-auto inline-block;
}

/* POPHOVER */
.popHover {
    @apply relative;
}
.popHover-content {
    @apply z-10 opacity-0 absolute top-2 left-9 h-6 bg-white px-2 py-1 text-xs rounded shadow-lg w-auto whitespace-nowrap;
}
.popHover-content.active {
    @apply opacity-95 sm:bottom-11 md:left-11;
    transition: opacity, left 0.2s;
}
