.forumSearchBar {
    @apply container mx-auto;
}
.forumSearchBar .forumSearchBar__label {
    @apply text-left;
}
.forumSearchBar .forumSearchBar__suggest {
    @apply m-auto p-0 relative;
}
.forumSearchBar .forumSearchBar__suggest .forumSearchBar__suggest__results {
    @apply absolute m-0 p-2 border border-transparent rounded rounded-t-none bg-white shadow-lg z-10;
    top: 37px;
    left: 1px;
    width: calc(100% - 2px);
}
.forumSearchBar .forumSearchBar__suggest .forumSearchBar__suggest__results .forumSearchBar__suggest__results__item {
    @apply p-1 m-1 cursor-pointer;
}
.forumSearchBar .forumSearchBar__suggest .forumSearchBar__suggest__results .forumSearchBar__suggest__results__item.active {
    @apply bg-blue-100 rounded;
}
