.mainSearchBar {
    @apply container m-auto w-full;
}
.mainSearchBar .mainSearchBar__label {
    @apply text-left;
}
.mainSearchBar .mainSearchBar__suggest {
    @apply m-0 p-0 relative w-auto;
}
.mainSearchBar .mainSearchBar__suggest input {
    @apply pl-2 rounded-full border-none;
    background: #ffffff;
    height: 34px;
}
.mainSearchBar .mainSearchBar__suggest input::placeholder {
    @apply text-graySkills pl-0 text-sm;
    /* color: rgb(230, 230, 230) !important; */
}
.mainSearchBar .mainSearchBar__suggest .mainSearchBar__suggest__results {
    @apply absolute mt-8 p-1 bg-secondary z-10;
    top: 37px;
    left: 1px;
    width: calc(100% - 2px);
}
.mainSearchBar .mainSearchBar__suggest .mainSearchBar__suggest__results .mainSearchBar__suggest__results__item {
    @apply p-2 m-1 cursor-pointer;
}
.mainSearchBar .mainSearchBar__suggest .mainSearchBar__suggest__results .mainSearchBar__suggest__results__item.active {
    @apply bg-blue-800 rounded-xl p-2;
}

.css-yk16xz-control,
.css-1pahdxg-control {
    @apply !rounded-3xl text-sm;
}
.css-2613qy-menu {
    @apply !text-sm;
}
