.discussions_list {
    /* height: calc(20vh); */
    @apply h-full;
}
.chatRoomRef {
    @apply fixed top-0 left-0 w-full h-full transition-all;
}
.chatRoom {
    @apply relative flex flex-col justify-start bottom-0 right-0 w-full sm:shadow overflow-hidden sm:w-96 z-20 bg-white;
}
.chatRoom_ai {
    @apply relative flex flex-col justify-start bottom-0 right-0 w-full sm:shadow overflow-hidden sm:w-96 z-20 bg-black;
}

.chatRoom_content {
    @apply relative px-2 z-10 m-0 overflow-y-auto bg-white h-full rounded;
    /* height: calc(100vh - 60px); */
}

.chatRoom_ai_content {
    @apply relative px-2 z-10 m-0 overflow-y-auto bg-black h-full rounded;
    /* height: calc(100vh - 60px); */
}

.chatRoom_content_messages {
    @apply inline-block break-words m-0 sm:pb-0 p-2 w-full h-auto bg-none text-left text-sm font-semibold overflow-hidden;
    white-space: pre-wrap;
    /* padding-bottom: 40px; */
    /* height: calc(100vh - 80px); */
}

.chatRoom_ai_content_messages {
    @apply inline-block break-words m-0 sm:pb-0 p-2 w-full h-auto bg-none text-left font-semibold text-sm overflow-hidden;
    white-space: pre-wrap;
    /* padding-bottom: 40px; */
    /* height: calc(100vh - 80px); */
}

.chatRoom_form {
    @apply relative left-0 bottom-0 z-10 w-full h-auto;
}

.chatRoom_ai_form {
    @apply relative left-0 bottom-0 z-10 w-full h-auto;
}

.chatRoom_content_input {
    @apply flex flex-row justify-between w-full h-auto p-0 bg-white border-t border-lightGraySkills rounded-b;
}
.chatRoom_ai_content_input {
    @apply flex flex-row justify-between w-full h-auto p-0 bg-black border-t border-lightGraySkills rounded-b;
}

.chatRoom_content_input textarea {
    @apply w-full max-h-40 text-mainText p-2 m-1 resize-none outline-none h-auto text-sm;
}
.chatRoom_ai_content_input textarea {
    @apply w-full max-h-40 bg-black text-white p-2 m-1 resize-none outline-none h-auto text-sm;
}

.chatRoom_content_input button {
    @apply w-10 p-2 h-10 text-center flex justify-center items-center text-graySkills;
}

.chatRoom_ai_content_input button {
    @apply w-10 p-2 h-10 text-center flex justify-center items-center text-white;
}

.chatRoom_content_noConnected {
    @apply relative flex flex-row justify-center items-center w-full h-10 p-0 bottom-0 left-0 bg-white border-t border-gray-100;
}
.chatRoom_content_noConnected button {
    @apply mt-2;
}

.chatRoom_content_message.default {
    @apply relative w-full mb-2 h-auto py-0 flex flex-row items-start gap-2;
}
.chatRoom_ai_content_message.default {
    @apply relative w-full mb-2 h-auto py-0 flex flex-row items-start gap-2;
}

.chatRoom_content_message.default span {
    @apply bg-lightGraySkills p-2 pl-3 rounded-r-2xl rounded-bl-2xl w-auto h-auto text-purpleSkills;
    max-width: 100%;
}
.chatRoom_ai_content_message.default span {
    @apply p-2 pl-3 rounded-r-2xl rounded-bl-2xl w-auto h-auto text-white;
    background-image: linear-gradient(5deg,  #7d21fd 0%, #b235fe 100%);
    max-width: 100%;
}

.chatRoom_content_message.mine {
    @apply relative w-full mb-2 text-right h-auto py-0 flex flex-row-reverse items-start gap-2;
}
.chatRoom_ai_content_message.mine {
    @apply relative w-full mb-2 text-right h-auto py-0 flex flex-row-reverse items-start gap-2;
}

.chatRoom_content_message.mine span {
    @apply bg-blueSelect  p-2 pr-3 rounded-l-2xl rounded-br-2xl w-auto h-auto text-purpleSkills;
    max-width: 100%;
}
.chatRoom_ai_content_message.mine span {
    @apply bg-blancSkills p-2 pr-3 rounded-l-2xl rounded-br-2xl w-auto h-auto text-purpleSkills;
    max-width: 100%;
}

@keyframes waiting {
    0% { content: "."; }
    35% { content: "."; }
    50% { content: ". ."; }
    75% { content: ". . ."; }
    100% { content: ". . . ."; }
}

.waiting-animation::after {
    content: "";
    animation: waiting 2s infinite;
}