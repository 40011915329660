.btn-report-group button { 
    @apply w-full border px-8 py-3 hover:bg-lightGraySkills;
}

.tree-group div {
    @apply w-full border px-8 py-3 hover:bg-lightGraySkills cursor-pointer;
}

.tree-group div:not(:last-child) {
    border-bottom: none; /* Prevent double borders */
}

.btn-report-group button:not(:last-child) {
    border-bottom: none; /* Prevent double borders */
}

button .list-motif {
    @apply text-left;
}