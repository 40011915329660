.Tabs {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

.Tabs_item_messagerie {
    @apply p-0 text-purpleSkills text-center font-extrabold w-full hover:text-white transition-colors duration-200;
}

.Tabs_item_messagerie.active {
    @apply text-white;
}

.Tabs_item_messagerie a {
    @apply w-full h-full px-4 py-3 inline-block;
}

.Tabs_item_messagerie div {
    @apply w-full h-full px-4 py-3 cursor-pointer;
}

.Tabs_messagerie_slider {
    @apply bg-white absolute bottom-0;
    height: 2px;
    transition: 0.25s;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
