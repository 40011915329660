.Tabs {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

.Tabs_item {
    @apply p-0 text-gray-500 sm:text-sm text-center font-extrabold w-full sm:hover:bg-white sm:hover:rounded-3xl transition-colors duration-200;
}

.Tabs_item.active {
    @apply text-purpleSkills;
}

.Tabs_item a {
    @apply w-full h-full px-4 py-3 inline-block;
}

.Tabs_item div {
    @apply w-full h-full px-4 py-3 cursor-pointer;
}

.Tabs_slider {
    @apply bg-purpleSkills;
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 10%;
    transition: 0.3s;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
