/* @import url("https://fonts.googleapis.com/css2?family=Lato&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@300&family=Exo:ital,wght@1,400&family=Mukta+Vaani:wght@400&family=Lato&family=Oswald&display=swap"); */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply font-nunito bg-blancSkills;
        scroll-behavior: smooth;
        transition: none;
    }
    .container-service {
        width: 100%;
        max-width: 100%; /* Make sure it never goes beyond the viewport width on smaller screens */
    }

    @media (min-width: 640px) {
        .container-service {
            max-width: 640px;
        }
    }

    @media (min-width: 768px) {
        .container-service {
            max-width: 968px;
        }
    }

    @media (min-width: 1024px) {
        .container-service {
            max-width: 1280px;
        }
    }
}

@layer base {
    .h1 {
        @apply font-bold;
        @apply text-2xl;
    }

    .h2 {
        @apply font-bold;
        @apply text-lg;
    }

    .h3 {
        @apply font-bold;
        @apply text-xl;
    }

    .h4 {
        @apply font-bold;
        @apply text-base;
    }

    .p {
        @apply font-normal mt-2;
    }

    .container {
        max-width: 1096px !important;
    }

    .content {
        @apply text-mainText text-sm md:text-base;
        /* @apply text-gray-600; */
    }
    .content p {
        @apply mb-2;
    }
    .content ul {
        @apply mb-2 list-disc ml-4;
    }
    .roomNews ul,
    ol {
        @apply mb-2 list-disc ml-4;
    }
}

@layer components {
    .text-shadow {
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .text-shadow-md {
        text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
    }

    .text-shadow-lg {
        text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11), 0 5px 15px rgba(0, 0, 0, 0.08);
    }

    .text-shadow-none {
        text-shadow: none;
    }
}

@layer components {
    .grid-sizer,
    .grid-item {
        width: 50%;
    }
    @media (min-width: 560px) {
        .grid-sizer,
        .grid-item {
            width: 33.333333%;
        }
    }
    @media (min-width: 640px) {
        .grid-sizer,
        .grid-item {
            width: 25%;
        }
    }
    @media (min-width: 1080px) {
        .grid-sizer,
        .grid-item {
            width: 20%;
        }
    }
}

@layer components {
    .header {
        @apply mt-4;
    }

    .button {
        @apply rounded;
        @apply px-4 cursor-pointer;
        @apply transition-colors duration-200;
        min-height: 42px;
    }
    .button:hover {
        @apply bg-graySkills text-white;
        @apply transition-colors duration-400;
    }
    .button-small {
        @apply px-2 py-1 text-sm;
        min-height: 0px;
    }
    .button-default {
        @apply bg-white text-graySkills m-1;
    }
    .button-primary {
        @apply bg-primary text-white m-1;
    }
    .button-secondary {
        @apply bg-secondary text-white m-1;
    }
    .button-disabled {
        @apply bg-gray-300 text-white m-1 cursor-not-allowed;
    }
    .button-success {
        @apply bg-success-400 text-white m-1;
    }
    .button-green {
        @apply bg-greenSkills text-white px-4 py-2 rounded-full;
    }
    .button-gray {
        @apply bg-graySkills text-white rounded-full;
    }
    .button-warning {
        @apply bg-warning-400 text-white m-1;
    }
    .button-danger {
        @apply bg-danger-400 text-white;
    }

    .button-justIcon {
        @apply bg-transparent;
        /* @apply transition-colors duration-200; */
    }
    .button-border-green {
        @apply border border-greenSkills text-greenSkills;
    }
    .button-border-gray {
        @apply border border-graySkills text-graySkills;
    }
    .counter-icon {
        /*  @apply flex flex-nowrap relative top-0 left-0 justify-center items-center rounded-full w-full h-full p-0; */
        @apply flex flex-nowrap relative top-0 left-0 items-center rounded-full w-full h-full p-0;
    }
    .counter-icon .counter-icon_number {
        @apply relative text-xs sm:text-base font-light;
        /* top: 3px; */
        left: -2px;
    }

    .link {
        @apply relative text-xs text-graySkills border border-graySkills rounded-full bg-white hover:bg-graySkills hover:text-white transition-colors duration-400;
        /* top: -2px; */
        min-height: 30.33px;
    }
    .linkCardHeader {
        @apply relative items-center text-xs text-center text-red border border-red h-8 font-medium rounded-2xl px-2 sm:px-3 py-1 hover:!border-blueMain hover:!text-blueMain transition-colors duration-400;
    }
    .linkCardHeaderMobile {
        @apply relative items-center text-red text-xs font-bold hover:border-red hover:text-blueMain transition-colors duration-400;
    }
    .linkCardHeader.active {
        @apply text-blueMain hover:!text-red border-none p-0;
    }
    .linkCard {
        @apply relative flex justify-center items-center text-xs text-center text-graySkills border border-graySkills rounded-full font-medium px-2 sm:px-3 sm:hover:!bg-graySkills sm:hover:!text-white transition-colors duration-400;
        min-height: 2rem;
    }
    .linkCardMainNavMobile {
        @apply relative w-12 h-12 bg-blancSkills border border-blancSkills justify-start items-start text-xs text-center text-gray-600 rounded-full font-medium hover:!bg-graySkills hover:!text-white transition-colors duration-400;
    }
    .linkCardApplyJob {
        @apply relative flex justify-center items-center text-sm text-center text-blueMain border border-blueMain rounded-full font-medium px-2 sm:px-3 hover:!bg-blueMain hover:!text-white transition-colors duration-400;
        min-height: 2rem;
    }
    .linkCardApplyJobModal {
        @apply relative flex justify-center items-center text-xl text-center text-white bg-blueMain border-2 border-blueMain rounded-full font-bold px-2 sm:px-5 hover:bg-white hover:text-blueMain transition-colors duration-400;
        min-height: 3rem;
    }
    .linkCardDashboard {
        @apply relative flex justify-center items-center text-xs text-center bg-blueMain text-white border border-blueMain rounded-full font-medium px-2 sm:px-3 hover:bg-white hover:text-blueMain transition-colors duration-400;
        min-height: 2rem;
    }
    .linkCard.active {
        @apply bg-graySkills !text-white;
    }

    .linkCard.green {
        @apply bg-white text-greenSkills border-greenSkills;
    }
    .linkCard.purpleSkills {
        @apply bg-purpleSkills !text-white;
    }
    .linkCard-purpleSkills {
        @apply relative flex justify-center items-center text-xs text-center bg-white text-purpleSkills rounded-full font-medium px-2 sm:px-3 sm:hover:bg-[#f7f9ff] sm:hover:!text-purpleSkills shadow-sm shadow-lightBlueSkills sm:hover:shadow-xl sm:hover:shadow-lightBlueSkills transition-shadow duration-500;
        min-height: 2rem;
    }
    .linkCard-purpleSkills-outline {
        @apply relative flex justify-center items-center text-xs text-center text-purpleSkills border border-purpleSkills rounded-full font-medium px-2 sm:px-3 hover:[#f7f9ff] hover:text-white shadow-sm shadow-lightBlueSkills hover:shadow-xl hover:shadow-lightBlueSkills transition-shadow duration-500;
        min-height: 2rem;
    }
    .linkCard.green.active,
    .linkCard.green:hover {
        @apply !bg-success-600 !text-white border-greenSkills;
    }

    .linkCard.red {
        @apply bg-purpleSkills text-white border-purpleSkills;
    }
    .linkCard.red.active,
    .linkCard.red:hover {
        @apply !bg-purpleSkills !text-white border-purpleSkills;
    }

    .linkCard.purple {
        @apply bg-white text-purpleSkills border-purpleSkills;
    }
    .linkCard.purple.active,
    .linkCard.purple:hover {
        @apply !bg-purpleSkills !text-white border-purpleSkills;
    }

    .linkCardShop {
        @apply relative text-lg text-center text-white border border-blueMain bg-blueMain rounded-3xl font-bold px-3 py-4 w-full h-12 sm:hover:text-blueMain sm:hover:bg-white transition-colors duration-200;
    }
    .linkCardContact {
        @apply relative text-lg text-center text-purpleSkills bg-blancSkills rounded-3xl font-bold px-2 py-4 w-full h-12 sm:hover:text-white sm:hover:bg-graySkills transition-colors duration-200;
    }
    .linkCard-red {
        @apply border-red text-red hover:border-red hover:text-white hover:bg-red;
    }
    .linkCard-red.active {
        @apply border-none text-red;
    }
    .linkCard_icon {
        @apply p-1 flex justify-center items-center text-center rounded-full w-8;
    }
    .link.link_blue {
        @apply font-bold pt-0.5 h-5;
        color: #4765ff;
    }
    .link.link_blue.active {
        @apply font-bold text-white pt-0.5 h-5;
        background: #4765ff;
    }
    .link.link_red {
        @apply border border-primary px-3 py-1 text-primary font-semibold w-32 hover:bg-primary hover:text-white;
    }
    .link.link_red.active {
        @apply bg-primary text-white;
    }
    .link_basic {
        @apply text-xs text-graySkills px-2 py-0.5 hover:text-blueMain overflow-hidden;
    }
    .link_basic.active {
        @apply text-blueMain font-bold;
    }
    .link_basic_dark {
        @apply text-xs text-gray-700 px-0 py-1.5 hover:text-blueMain overflow-hidden;
    }

    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
        position: relative;
    }
    .loader svg {
        position: relative;
        width: 40px;
        height: 40px;
        margin: 20px;
        display: inline-block;
    }

    .video-fit-cover video {
        object-fit: cover;
    }
    .heightWrapPlayer {
        /* height: 100vh; */
        height: auto;
        /* max-height: 100vh; */
    }
    .heightPlayer-portrait-mobile {
        height: auto;
        width: 100%;
    }
    .heightPlayer-landscape-mobile {
        height: auto;
        width: 100%;
    }
    .heightPlayer-portrait {
        height: 70vh;
        width: auto;
    }
    .heightPlayer-landscape {
        height: auto;
        width: 100%;
    }

    /* For Webkit-based browsers (Chrome, Safari and Opera) */
    .scrollbar-hide::-webkit-scrollbar {
        display: none;
    }

    /* For IE, Edge and Firefox */
    .scrollbar-hide {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    footer {
        @apply text-center h-auto mt-16 relative;
        /* @apply text-white bg-blueMain; */
        @apply text-white;
    }
    footer .footer_rounded {
        width: 100%;
        position: relative;
        top: -55px;
        overflow: hidden;
    }
    footer .footer_rounded .footer_radius:after {
        @apply bg-blueMain;
        content: '';
        -webkit-box-shadow: 1px 1px 9px 0 #0031ff;
        box-shadow: 1px 1px 9px 0 #0031ff;
        display: block;
        border-radius: 90% 90% 0 0/90% 90% 0 90%;
        margin: 1.2rem -2.4rem 0;
        height: 4.4em;
    }
    footer .footer_content {
        @apply relative;
        top: -100px;
    }

    footer .footer_bottom {
        @apply w-full h-auto md:h-10 bg-blue-900 text-white text-sm;
        @apply flex flex-col md:flex-row justify-center items-center;
        background: #0031ff;
    }
    footer .footer_bottom a {
        @apply hover:underline;
    }
}

@layer utilities {
    @variants responsive {
        /* Hide scrollbar for Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
        }
    }
}
