/* .connectedRoom {
    @apply relative bg-blue-500 h-screen;
} */

.connectedRoom {
    @apply relative h-auto flex flex-row justify-start items-center  w-full;
    /* width: 80px; */
    /* height: calc(100vh - 40px); */
}
.connectedRoom_content {
    @apply relative pt-4 h-auto;
    /* padding-bottom: 80px; */
    /* height: calc(100vh - 40px); */
    /* height: 1%; */
}

.footerCardFrontRoom {
    @apply relative cursor-pointer px-2 py-2;
    @apply rounded-full;
    @apply hover:bg-white text-graySkills hover:text-blueMain;
}
