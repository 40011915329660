:root {
    --swiper-navigation-size: 44px;
    --swiper-navigation-color: white;
}
.swiper-button-next,
.swiper-button-prev {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
}
.swiper-button-next.swiper-button-hidden,
.swiper-button-prev.swiper-button-hidden {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
}
.swiper-navigation-disabled .swiper-button-next,
.swiper-navigation-disabled .swiper-button-prev {
    display: none !important;
}
.swiper-button-next:after,
.swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, 10px);
    right: auto;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    content: 'prev';
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, 10px);
    left: auto;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    content: 'next';
}
.swiper-button-lock {
    display: none;
}
