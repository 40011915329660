.ai-container {
    background-image: linear-gradient(150deg, #b235fe 0%, #7d21fd 100%);
    box-shadow: 0px 10px 25px -8px #5200a3;
}

.ai-shadow {
    box-shadow: 0px 10px 25px -8px #5200a3;
}

.discussion-container {
    @apply bg-white;
}

.header-ai {
    @apply text-white bg-black;
}

.header-discussion {
}
