.slick-slider {
    @apply relative;
    display: flex;
    justify-content: center;
}

.slick-dots {
    @apply relative bottom-0;
}

.slick-track {
    @apply w-full h-full;
}

.slick-slide {
    @apply w-full h-full;
}

.slick-slide div {
    @apply w-full h-full;
}

.slick-dots li {
    @apply relative inline-block p-0 m-1 cursor-pointer w-auto h-auto;
}

.slick-dots .slick-active {
    @apply w-auto h-auto;
}

.image-slider .slick-list {
    @apply relative flex flex-col justify-center overflow-hidden m-0 p-0 w-full h-auto pt-2 sm:max-h-[30rem];
}

.medias-need-slider .slick-list {
    @apply relative flex flex-col justify-center overflow-hidden m-0 p-0 w-full h-full sm:max-h-[10rem];
}

.custom-slider .slick-list {
    @apply relative flex flex-col justify-center overflow-hidden m-0 p-0 w-full h-[20rem] max-h-[20rem] sm:h-[30rem] sm:max-h-[30rem];
}

.standard-slider .slick-list {
    @apply relative overflow-hidden m-0 p-0 w-full h-auto;
}

.react-player video {
    max-height: 30rem;
}

.medias-need-react-player video {
    @apply h-full sm:max-h-[10rem];
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 25px;
    height: 25px;
    cursor: pointer;
    color: white;
    border: 0;
    outline: none;
    background: transparent;
    padding: 0;
}

.custom-dots-container button {
    @apply w-1.5 h-1.5 bg-gray-400 rounded-full border-none cursor-pointer transition-colors duration-300;
}

.custom-dots-container button.active {
    @apply bg-white;
}
