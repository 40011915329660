/* Style général pour le conteneur de slides */
.profil-slider-container {
    @apply relative overflow-hidden w-full h-full;
}

/* Style pour les slides */
.carousel-slide {
    width: 80%;
    height: 100%;
    transition: transform 0.3s ease, opacity 0.3s ease;
    position: relative;
    z-index: 1;
    will-change: transform, opacity;
}

/* Effet pour les slides adjacents */
.carousel-slide:not(.swiper-slide-active) {
    transform: scale(0.85);
    opacity: 0.6;
    z-index: 1;
}

/* Style pour le slide actif */
.carousel-slide.swiper-slide-active {
    transform: scale(1);
    opacity: 1;
    z-index: 10;
}

/* Positionnement et style des flèches de navigation */
.swiper-button-prev,
.swiper-button-next {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
    background-color: rgba(0, 0, 0, 0.6);
}

.swiper-button-prev {
    left: 10px;
}

.swiper-button-next {
    right: 10px;
}
