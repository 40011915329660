.Tabs_mobile_shoot {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

.Tabs_mobile_shoot_item {
    @apply p-0 w-auto text-gray-400 text-center font-bold sm:hover:bg-white sm:hover:rounded-3xl transition-colors duration-200;
}

.Tabs_mobile_shoot_item.active {
    @apply text-white;
}

.Tabs_mobile_shoot_item a {
    @apply w-full h-full px-2 py-3 inline-block;
}

.Tabs_mobile_shoot_item div {
    @apply w-full h-full px-4 py-3 cursor-pointer;
}

.Tabs_mobile_shoot_slider {
    @apply bg-white;
    position: absolute;
    bottom: 0;
    height: 3px;
    transition: 0.25s;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
