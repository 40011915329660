/* main wrapper */
.react-timekeeper {
    @apply shadow-none !important;
}
.react-timekeeper__clock-wrapper {
    @apply bg-transparent !important;
}
.react-timekeeper__top-bar {
    @apply hidden !important;
}
