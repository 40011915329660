.rc-rate {
    /* @apply mt-2 !important; */
}
.rc-rate .rc-rate-star {
    margin: 0px !important;
}
.rc-rate .rc-rate-star * {
    font-size: 12px !important;
}
.rc-rate .rc-rate-star-zero * {
    @apply text-gray-300 !important;
}
.rc-rate .rc-rate-star-full * {
    @apply text-gold-400 !important;
}
