.imageEditor {
    @apply flex items-start fixed justify-center items-center top-0 left-0 w-full h-full;
    @apply backdrop-filter backdrop-blur-sm;
    background: rgba(0, 0, 0, 0.5);
    z-index: 50;
}

.imageEditor__wrap {
    @apply bg-black rounded-lg flex flex-col justify-between items-center;
    width: 98%;
    height: 98%;
}

.imageEditor__cropper {
    @apply flex gap-2 flex-col justify-center items-center h-full w-full;
    position: relative;
}
.imageEditor__cropper > div:first-child {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 70vh;
}

.imageEditor__cropper-overlay {
    transition: 0.5s;
}

.imageEditor__cropper-overlay--faded {
    color: rgba(0, 0, 0, 0.9);
}

/* NAVIGATION */
.imageEditor_navigation {
    @apply border-t border-gray-900 p-4 w-full flex flex-row gap-2 justify-center;
}

.imageEditor_navigation__button {
    @apply ease-in-out duration-400 transition-all;
    @apply bg-gray-900 hover:bg-gray-800 rounded-full cursor-pointer p-2;
}
.imageEditor_navigation__button.active {
    @apply bg-gray-800;
}
.imageEditor_navigation__button.active svg {
    @apply fill-greenSkills;
}

/* SLIDER */
.imageEditor_slider {
    @apply flex flex-col justify-center items-center;
    @apply cursor-pointer absolute bottom-4;
    width: 100%;
    height: 20px;
    border-radius: 10px;
    max-width: 320px;
    background: rgba(0, 0, 0, 0.4);
    padding-left: 16px;
    padding-right: 16px;
}

.imageEditor_slider__wrapper {
    display: flex;
    align-items: center;
    max-width: 400px;
    width: 100%;
}

.imageEditor_slider__line {
    background-color: rgba(255, 255, 255, 0.5);
    height: 2px;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
}

.imageEditor_slider__fill {
    background: white;
    align-self: stretch;
    flex-basis: auto;
    flex-direction: column;
    flex-shrink: 0;
    position: absolute;
    height: 2px;
}

.imageEditor_slider__dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.imageEditor_slider__value {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transition-duration: 0.5s;
    transition-property: font-size, opacity;
    color: white;
    font-size: 10px;
    font-weight: 500;
    top: -20px;
    vertical-align: bottom;
    transform: translate(-50%);
}

.imageEditor_slider__handler {
    width: 2px;
    height: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transition-duration: 0.2s;
    transition-property: height;
    background-color: white;
}
.imageEditor_slider__handler:hover {
    height: 10px;
}
.imageEditor_slider__handler--focus {
    height: 10px;
}
.imageEditor_slider__handler--hidden {
    height: 4px;
}

.imageEditor_slider__inner-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    transform: scale(1);
    transition-duration: 0.1s;
    transition-property: transform;
    box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 7px, rgba(255, 255, 255, 0.15) 0px 1px 3px 1px;
}
.imageEditor_slider__inner-circle--focus {
    transform: scale(1.2);
}

/* PREVIEW */
.adjustable-image-source {
    display: none;
}
.canvas-image-source {
    display: none;
}
.editorPreview {
    overflow: hidden;
    box-sizing: border-box;
}
.editorPreview__image {
    pointer-events: none;
    position: absolute;
    user-select: none;
    transform-origin: center;
}
