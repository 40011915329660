.css-sk39y4-Mt {
    height: 100%;
}

.css-1wa3eu0-placeholder {
    margin-left: 0.25rem;
    color: #9fa5c0;
    width: 100%;
}

.css-1hwfws3 {
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.css-e8izw0-Mt {
    height: 100%;
    padding: 0;
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-left: 0.25rem;
}

.css-1uccc91-singleValue {
    display: contents;
}
